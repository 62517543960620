import { createSlice, createAction, current } from '@reduxjs/toolkit';

import measurements from 'store/measurements';

import { setLocalStorage } from '../common/utils';
import {
  createCatalog,
  editCatalog,
  getCatalog,
  publishCatalog,
  // generateCatalog,
} from './thunks';


const removeDistrict = createAction('removeDistrict');
const addProject = createAction('addProject');
const removeProject = createAction('removeProject');
const addUnit = createAction('addUnit');
const removeUnit = createAction('removeUnit');
const sort = createAction('sort');
const clearCatalog = createAction('clearCatalog');
const setSettings = createAction('setSettings');
const resetSettings = createAction('resetSettings');
const dropCatalog = createAction('dropCatalog');

const defaultCatalog = {
  id: undefined,
  name: '',
  lang: 'en',
  mainCurrency: measurements.getState().priceMeasurement || 'USD',
  additionalCurrency: null,
  areaMeasurementUnit: 'ft2',
  theme: 'dark',
  socialNetworks: [],
  unitViewType: 'table',
  showCustomSlide: false,
  customSlideTitle: '',
  customSlideText: '',
  customSlideImgId: null,
  hideSoldUnits: false,
  hideUnitNumber: false,
  showFullUnitsList: false,
  showAreaSlide: false,
  districts: [],
};

const saveToLocalStorage = (state) => {
  const links = state?.links?.map(({ id }) => ({ id }));
  const districts = state?.districts?.map((district) => ({
    ...district,
    projects: district?.projects?.map((project) => ({
      ...project,
      developer: { id: project?.developer?.id },
      units: project?.units?.map(({ id }) => ({ id })),
    })),
  }));

  setLocalStorage('catalog', JSON.stringify({ ...state, links, districts }));
};

const localStotageState = JSON.parse(localStorage.catalog || '{}');

const initialState = localStorage.catalog // eslint-disable-line
  ? localStotageState.length ? defaultCatalog : JSON.parse(localStorage.catalog || '{}') // TODO удалить проверку на length 08.2024
  : defaultCatalog;

const reducers = {
  [removeDistrict]: (state, { payload: { districtId } }) => {
    state.districts = state.districts.filter((item) => item.id !== districtId);
    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [addProject]: (state, { payload: { projectId, districtId } }) => {
    const district = state.districts.find((item) => item.id === districtId);

    if (!district) {
      const order = state?.districts?.length
        ? Math.max(...state.districts.map((item) => item.order || 0)) + 1
        : 1;

      state.districts.push({
        id: districtId,
        order,
        projects: [{
          id: projectId,
          order: 1,
          units: [],
        }],
      });
    } else {
      const project = district.projects.find((item) => item.id === projectId);

      if (!project) {
        const order = Math.max(...district.projects.map((item) => item.order)) + 1;

        district.projects.push({
          id: projectId,
          order,
          units: [],
        });
      }
    }

    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [removeProject]: (state, { payload: { projectId, districtId } }) => {
    const district = state.districts.find((item) => item.id === districtId);
    district.projects = district.projects.filter((project) => project.id !== projectId);

    if (!district.projects.length) state.districts = state.districts.filter((item) => item.id !== district.id);

    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [addUnit]: (state, { payload: { unitId, projectId, districtId } }) => {
    const districtsLength = state?.districts?.length;
    const district = state?.districts?.find((item) => item.id === districtId);
    const project = district?.projects?.find((item) => item.id === projectId);

    if (districtsLength === undefined) state.districts = [];

    if (!district) {
      const order = state?.districts?.length
        ? Math.max(...state.districts.map((item) => item.order || 0)) + 1
        : 1;

      state.districts.push({
        id: districtId,
        order,
        projects: [{
          id: projectId,
          order: 1,
          units: [{ id: unitId }],
        }],
      });
    } else if (!project) {
      const order = Math.max(...district.projects.map((item) => item.order)) + 1;
      district.projects.push({ id: projectId, order, units: [{ id: unitId }] });
    } else {
      project.units.push({ id: unitId });
    }

    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [sort]: (state, { payload: districts }) => {
    state.districts = districts;
    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [removeUnit]: (state, { payload: { unitId, projectId, districtId } }) => {
    const districtIndex = state.districts.findIndex((item) => item.id === districtId);
    const district = state.districts[districtIndex];
    const projectIndex = district?.projects?.findIndex((item) => item.id === projectId);
    const project = district?.projects[projectIndex];

    project.units = project.units.filter((unit) => unit?.id !== unitId);
    if (!project.units.length) district.projects = district.projects.filter((item) => item.id !== projectId);
    if (!district.projects.length) state.districts = state.districts.filter((item) => item.id !== districtId);

    state.haveChanges = true;

    saveToLocalStorage(current(state));
  },

  [clearCatalog]: (state) => {
    const newState = {
      ...state,
      districts: [],
      haveChanges: true,
    };

    setLocalStorage('catalog', JSON.stringify(newState));
    return newState;
  },

  [setSettings]: (state, { payload }) => {
    const newState = {
      ...state,
      ...payload,
      districts: state.districts,
      links: state.links,
      haveChanges: true,
    };

    setLocalStorage('catalog', JSON.stringify(newState));
    return newState;
  },

  [resetSettings]: (state) => {
    const newState = {
      ...state,
      ...defaultCatalog,
      districts: state.districts,
      links: state.links,
      haveChanges: true,
    };

    setLocalStorage('catalog', JSON.stringify(newState));

    return newState;
  },

  [dropCatalog]: () => {
    setLocalStorage('catalog', '');

    return defaultCatalog;
  },
};

const extraReducers = (builder) => builder
  .addCase(getCatalog.fulfilled, (state, { payload }) => {
    saveToLocalStorage(payload);

    return payload;
  })
  .addCase(getCatalog.rejected, () => {
    setLocalStorage('catalog', '');

    return defaultCatalog;
  })
  .addCase(createCatalog.fulfilled, (state, { payload }) => {
    saveToLocalStorage(payload);

    return payload;
  })
  .addCase(editCatalog.fulfilled, (state, { payload }) => {
    saveToLocalStorage(payload);

    return payload;
  })
  .addCase(publishCatalog.fulfilled, (state, { payload }) => {
    saveToLocalStorage({ ...payload, id: undefined });

    return { ...payload, id: undefined };
  });
/* .addCase(generateCatalog.fulfilled, (state, { payload }) => {

  }) */

export const { actions, reducer, name } = createSlice({
  name: 'catalog',
  initialState,
  reducers,
  extraReducers,
});
